(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/deposit-restriction/assets/javascripts/restriction.js') >= 0) return;  svs.modules.push('/components/accountservices/deposit-restriction/assets/javascripts/restriction.js');

'use strict';

class DepositRestriction {
  constructor() {
    const form = document.getElementById('deposit-restriction-form');
    this.currentNumberOfDeposits = 0;
    if (form) {
      this.selectedNumberOfDeposits = false;
      this.dialogs = new svs.accountservices.deposit_restriction.DepositRestrictionDialogs();
      this.radioButtons = document.querySelectorAll('.js-deposit-restriction .radio-button');
      this.submitButton = document.querySelector('.js-deposit-restriction-submit');
      this.formToggleDivs = document.querySelector('.js-deposit-restriction .form-toggle-group');
      this.radioButtons.forEach(radioButton => {
        radioButton.addEventListener('click', e => {
          this.depositRestrictionButtonClick(e);
        });
      });
      form.addEventListener('submit', e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const numberOfDeposits = parseInt(Object.values(Object.fromEntries(formData))[0], 10);
        svs.ui.dialog.api.add(this.dialogs.showSetDepositLimitsDialog(numberOfDeposits));
      });
      svs.accountservices.playscanLog.postToPlayscan({
        intent: 'setdepositslimit',
        page: 'deposits',
        payload: {
          shown: 1
        },
        session_id: 'depositsexperiment',
        site: 'default'
      });
    }
  }
  depositRestrictionButtonClick(e) {
    var _e$target;
    const hasSelected = Array.from(this.radioButtons).filter(radioButton => radioButton.checked).length;
    const numDeposits = (_e$target = e.target) === null || _e$target === void 0 ? void 0 : _e$target.value;
    if (hasSelected) {
      this.submitButton.removeAttribute('disabled');
    }
    if (numDeposits !== this.selectedNumberOfDeposits) {
      this.selectedNumberOfDeposits = numDeposits;
      svs.accountservices.playscanLog.postToPlayscan({
        intent: 'setdepositslimit',
        page: 'deposits',
        payload: {
          clicked: 1
        },
        session_id: 'depositsexperiment',
        site: 'default'
      });
    }
  }

  onDepositComplete() {
    this.currentNumberOfDeposits++;
  }

  onDepositStart() {
    var _svs$player_deposit$d;
    const data = (_svs$player_deposit$d = svs.player_deposit.data) === null || _svs$player_deposit$d === void 0 ? void 0 : _svs$player_deposit$d.depositRestrictionData;
    if (data) {
      if (data.maxDeposits) {
        if (data.currentDeposits + this.currentNumberOfDeposits >= data.maxDeposits) {
          svs.accountservices.deposit_restriction.DepositRestrictionDialogs.showNotAllowToDepositDialog();
          return false;
        }
      } else if (data.currentDeposits + this.currentNumberOfDeposits >= 3) {
        let returnUrl = svs.utils.url.queryString.returnUrl;
        returnUrl = returnUrl ? "?returnUrl=".concat(svs.utils.url.makeInternal(returnUrl)) : '';
        svs.utils.url.navigate("".concat(svs.core.urlMapping.get('transactionsHome'), "/begransning").concat(returnUrl));
        return false;
      }
    }
    return true;
  }
}
svs.accountservices = svs.accountservices || {};
svs.accountservices.deposit_restriction = svs.accountservices.deposit_restriction || {};
svs.accountservices.deposit_restriction.depositRestriction = new DepositRestriction();

 })(window);